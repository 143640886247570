import { getInfoApi, googleLoginApi, logout } from '@/apis/login';

const user = {
  namespaced: true,
  state: {
    userInfo: null,
    token: '' || localStorage.getItem('token')
  },
  mutations: {
    TOKEN(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    USER_INFO(state, info) {
      state.userInfo = info;
      localStorage.setItem('userInfo', JSON.stringify(info));
    }
  },
  actions: {
    saveToken({ commit }, data) {
      commit('TOKEN', data);
    },

    saveUserInfo({ commit }, data) {
      commit('USER_INFO', data);
    },

    //google快捷登录
    googleLogin({ commit, state }) {
      return new Promise((resolve, reject) => {
        googleLoginApi(state.credential)
          .then((response) => {
            console.log(response, '32===');
            // const { user } = response
            // commit('USER_INFO', user)
            // resolve(user)
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    //用户信息
    getInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfoApi()
          .then((response) => {
            // const { user } = response
            commit('USER_INFO', response);
            localStorage.setItem('response', JSON.stringify(response));
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 退出登录
    logout({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout({ Authorization: state.token })
          .then(() => {
            commit('RESET_STATE');
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }
};
export default user;
