import { createStore } from 'vuex'
import user from './modules/user';

export default createStore({
  state: {
    type:"chinese",
    sidebarOpen:false,
  },
  mutations: {
    setEnglish(state){
      state.type = "english";
    },
    setChinese(state){
      state.type = "chinese";
    },
    TOGGLE_SIDEBAR(state) {
      state.sidebarOpen = !state.sidebarOpen
    },
  },
  actions: {
  },
  modules: {
    user
  }
})
